@mixin __FF_ORDER_VERSIONING__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_MAP_PRICE_STRIKE_THROUGH__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_WINTER_TREADWELL__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_LUG_NUT_ADD_EDIT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_DISABLE_UNUSED_ROUTES__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_SAME_DAY_VOID__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_TERMINAL_CUSTOMER_PRINTOUT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_ORDER_HISTORY_RESTRICT_REFERENCE_ACTIONS__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@import '../../../shared/scss/dt-base';

%option {
  padding: 12px 10px;
  background: transparent;
  color: $grey-800;

  @include media($min-sm) {
    padding: 4px;
  }

  @include media($min-md) {
    padding: 5px;
  }

  @include media($min-lg) {
    padding: 12px 11px;
  }
}

%popover {
  top: 52px;
  border: 1px solid $medium-light-gray;

  :global {
    .popover__pointer {
      border: 1px solid $medium-light-gray;
      background: $white;
    }

    .popover__child-wrapper {
      background: $white;
    }
  }

  @include media($min-sm) {
    display: block;
  }
}

.wrapper {
  position: fixed;
  width: 100%;
  background-color: $white;
  border-bottom: 1px solid $dt-nav-light-gray;
  z-index: 10;
}

.wrapper--extended-links {
  height: 164px;
}

.container {
  display: flex;
  align-items: center;
  width: 100%;
  height: 105px;
  margin: 0 auto;
  max-width: 1720px;

  @include media($min-sm) {
    height: 112px;
    padding: 0 20px;
  }

  @include media($min-md) {
    padding: 0 40px;
  }

  @include media($min-xl) {
    padding: 0 60px;
  }
}

.container--bottom-border {
  border-bottom: 1px solid $cec-light-gray;
}

.mini-cart-popover {
  &:global(.mini-cart-popover) {
    @extend %popover;
    width: 335px;
  }

  &:global(.mini-cart-popover--empty) {
    width: 300px;
  }
}

.my-store-popover {
  @extend %popover;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 320px;
  height: 240px;
}

.my-account-popover {
  @extend %popover;
  right: 10px;
  width: 183px;
}

.my-account-popover-list {
  width: 100%;
}

.my-account-list-item {
  &:first-child {
    margin: 0;
    color: $medium-gray;
    background: $dt-light-medium-gray;
  }

  padding: 16px 5px;
  font-size: 0.875rem;
  text-align: center;
  border-bottom: 1px solid $light-gray;
}

.site-logo {
  display: block;
  flex-shrink: 0;
  width: 75px;

  @include media($min-sm) {
    width: 150px;
    padding-right: 30px;
  }

  @include media($min-md) {
    width: 180px;
  }

  img {
    width: 100%;
    height: auto;
  }
}

.nav {
  flex: 1 0;
  height: 100%;
}

.nav-section {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  min-height: 65px;
  border-bottom: 0.5px solid $grey-500;

  &:last-child {
    border: 0;
    max-height: 50px;
    min-height: 40px;
  }

  @include media($min-sm) {
    padding: 0;
    height: 50%;
    min-height: 40px;
  }
}

.nav-links {
  display: flex;
  align-items: center;

  .nav-link {
    @extend %option;
    text-transform: uppercase;
    display: flex;

    .arrow-icon {
      color: $grey-800;
      margin-left: 4px;
      font-size: rem(17);
      vertical-align: -4px;
    }
  }
}

.menu-button {
  @extend %option;
  text-transform: uppercase;
  margin: 4px 0;
}

.menu-icon {
  margin-right: 3px;
  font-size: rem(13);
}

.arrow-icon {
  margin-left: 12px;
  color: $grey-600;
  pointer-events: none;
}

.marker-icon {
  color: $dt-red;
  font-size: rem(14);
  margin-right: 5px;
}

svg.user-icon {
  font-size: rem(18);
  color: $blue-500;
}

.nav-buttons {
  display: flex;
  justify-content: flex-end;
  flex-shrink: 0;
  width: 82px;
  margin-left: -10px;
  padding-right: 10px;

  @include media($min-sm) {
    width: auto;
    margin: 0 0 0 auto;
    padding-right: 0;
  }

  button::before {
    @include media($min-sm) {
      content: '';
      position: absolute;
      left: 0px;
      height: 22px;
      width: 1px;
      background: $dt-nav-light-gray;
    }
  }

  .myaccount-link {
    color: $dt-blue;
  }

  .myaccount-link::before {
    display: none;
  }

  .myaccount-link::after {
    content: '';
    position: absolute;
    right: 0px;
    height: 22px;
    width: 1px;
    background: $dt-nav-light-gray;
  }
}

.nav-button {
  display: flex;
  align-items: center;
  position: relative;
  @extend %option;
  white-space: nowrap;

  @include media($min-sm) {
    text-transform: none;

    .arrow-icon {
      margin-left: 8px;
    }
  }

  .arrow-icon {
    margin-left: 5px;
    font-size: rem(22);
  }

  .search-placeholder {
    margin-right: 15px;
    color: $grey-600;
  }

  :global .header__icon {
    margin-right: 4px;
    font-size: rem(16);
  }

  & > * {
    pointer-events: none;
  }
}

.nav-notification {
  position: absolute;
  top: 9px;
  margin-left: 13px;

  @include media($min-sm) {
    position: static;
    margin-left: 5px;
  }
}

.sign-in {
  flex-shrink: 0;
  margin-left: 7px;
  font-weight: 700;
}

.feature-label {
  flex-shrink: 0;
  margin-left: 7px;
  font-weight: 500;
}

.my-store-address {
  max-width: 27vw;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: $grey-800;

  @include media($min-md) {
    max-width: 27vw;
  }
}

.skip {
  position: absolute;
  top: -10000px;
}

.search-placeholder {
  margin-right: 15px;
  color: $grey-600;
}

.user-popover {
  &:global(__pointer) {
    background: $dt-light-medium-gray;

    @include media($min-sm) {
      background: $white;
    }
  }
}

.greeting {
  color: $grey-800;

  @include media($min-sm) {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 70px;
  }

  @include media($min-md) {
    max-width: 400px;
  }
}

.user-options {
  width: 185px;

  li {
    margin: 0 10px;
    padding: 16px 5px;
    font-size: 0.875rem;
    text-align: center;
    border-bottom: 1px solid $light-gray;
  }

  .mobile-greeting {
    background: $dt-light-medium-gray;
    margin: 0;
  }
}

.store-summary {
  display: flex;
  line-height: rem(16);
  font-size: rem(13);
}

.store-number {
  display: flex;
  padding-left: 0;
  font-size: rem(14);
  font-weight: 800;
}

.store-description {
  display: flex;
  flex-direction: row;
  margin-right: 20px;
}

.detailed-info {
  margin-left: 14px;
  font-size: rem(12);
  line-height: rem(16);
}

.detailed-info--address {
  text-transform: capitalize;
}

.my-store-nav-button {
  display: flex;
  align-items: center;
  position: relative;
  font-size: rem(14);
  white-space: nowrap;

  @include media($min-sm) {
    font-weight: 600;
    font-size: rem(13);
    text-transform: none;
  }
}

.dropdown-menu {
  display: flex;
}

.cart-button {
  @include media($min-sm) {
    padding-left: 13px;
  }

  @include media($min-lg) {
    padding: 12px;
  }
}
