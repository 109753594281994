@mixin __FF_ORDER_VERSIONING__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_MAP_PRICE_STRIKE_THROUGH__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_WINTER_TREADWELL__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_LUG_NUT_ADD_EDIT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_DISABLE_UNUSED_ROUTES__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_SAME_DAY_VOID__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_TERMINAL_CUSTOMER_PRINTOUT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_ORDER_HISTORY_RESTRICT_REFERENCE_ACTIONS__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@import '../../shared/scss/_dt-base';

%button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  max-width: 343px;
  min-width: 165px;
  min-height: 48px;
  text-transform: uppercase;
  text-align: center;
  color: $blue-700;
  cursor: pointer;
  border-radius: 3px;
  transition: background 0.4s ease-in;
  font-size: rem(16);
  font-weight: $bolder;
  line-height: rem(24);

  &:hover,
  &:active {
    color: $blue-700;
  }

  &:focus {
    outline: 2px solid $gold-500;
    outline-offset: -2px;
  }

  &:disabled {
    color: $grey-800;
    border: none;
    opacity: 0.5;

    &:hover,
    &:active,
    &:focus {
      cursor: not-allowed;
    }
  }
}

.tertiary {
  @extend %button;
  font-weight: $bolder;

  &:visited {
    color: $blue-700;
  }
}

.secondary {
  @extend %button;
  border: 2px solid $red-500;
  background: $white;
  color: $red-500;
  padding: 10px;

  &:visited {
    color: $red-500;
  }

  &:hover {
    background: $white;
    color: $red-700;
    border-color: $red-700;
  }

  &:active,
  &:focus {
    color: $red-500;
    background: $white;
  }

  &:disabled {
    border: 2px solid $grey-300;
    background: $white;
    color: $grey-300;

    &:hover {
      border: 2px solid $grey-300;
      background: $white;
      color: $grey-300;
    }
  }
}

.primary {
  @extend %button;
  background: $red-500;
  color: $white;

  &:visited {
    color: $white;
  }

  &:hover {
    background: $red-700;
    color: $white;
  }

  &:active,
  &:focus {
    color: $white;
    background: darken($dt-race-red, 4%);
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
    color: $white;
  }
}

.default {
  background: transparent;
  display: inline-block;
  padding: 0;
  color: $blue-700;
  font-weight: $bold;

  &:hover {
    color: $blue-500;
  }

  &:disabled {
    cursor: auto;
    color: $medium-gray;
  }
}

.full-width {
  width: 100%;
}

.icon {
  margin-right: 8px;
}
