@mixin __FF_ORDER_VERSIONING__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_MAP_PRICE_STRIKE_THROUGH__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_WINTER_TREADWELL__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_LUG_NUT_ADD_EDIT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_DISABLE_UNUSED_ROUTES__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_SAME_DAY_VOID__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_TERMINAL_CUSTOMER_PRINTOUT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_ORDER_HISTORY_RESTRICT_REFERENCE_ACTIONS__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@import '../../shared/scss/dt-base';

%option {
  padding: 12px;
  background: transparent;
  color: $dark-gray;
  font-weight: 700;
}

.search-bar {
  position: absolute;
  top: -50px;
  left: 0;
  width: 100%;
  height: 50px;
  background: $dt-light-medium-gray;
  z-index: 2;

  @include media($min-sm) {
    top: 3px;
    left: auto;
    right: 0;
    margin: 0 -10px;
    max-width: 420px;
    min-width: 305px;
  }
}

.nav-button {
  display: flex;
  align-items: center;
  position: relative;
  @extend %option;
  font-size: rem(14);

  @include media($min-sm) {
    font-weight: 600;
    font-size: rem(13);
    text-transform: none;
  }

  & > * {
    pointer-events: none;
  }
}

.search-results-container {
  display: none;
  flex-direction: row;
  border: 1px solid $border-gray;
  background: $white;

  @include media($min-xs) {
    display: flex;
  }
}

.category-container {
  border-left: 1px solid #d3d3d3;
  padding-left: 10px;
  margin-bottom: 20px;
}

.category-label {
  margin-top: 5px;
  margin-bottom: 10px;
}

.category-name {
  font-size: 0.75rem;
  color: #000;
}
