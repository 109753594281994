@mixin __FF_ORDER_VERSIONING__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_MAP_PRICE_STRIKE_THROUGH__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_WINTER_TREADWELL__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_LUG_NUT_ADD_EDIT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_DISABLE_UNUSED_ROUTES__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_SAME_DAY_VOID__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_TERMINAL_CUSTOMER_PRINTOUT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_ORDER_HISTORY_RESTRICT_REFERENCE_ACTIONS__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@import '../../shared/scss/_dt-base';

.h1 {
  @extend %h1;
}

.h2 {
  @extend %h2;
}

.h3 {
  @extend %h3;
}

.h4 {
  @extend %h4;
}

.h5 {
  @extend %h5;
}

.h6 {
  @extend %h6;
}

.p {
  margin: 0;
}

.p-regular {
  @extend %p-regular;
}

.p-small {
  @extend %p-small;
}

.p-extra-small {
  @extend %p-extra-small;
}

.h1-medium,
.h2-medium,
.h3-medium,
.h4-medium,
.h5-medium,
.h6-medium,
.p-medium {
  font-weight: $normal;
}

.h1-heavy,
.h2-heavy,
.h3-heavy,
.h4-heavy,
.h5-heavy,
.h6-heavy,
.p-heavy {
  font-weight: $bold;
}

.h1-black,
.h2-black,
.h3-black,
.h4-black,
.h5-black,
.h6-black,
.p-black {
  font-weight: $bolder;
}
