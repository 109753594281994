@mixin __FF_ORDER_VERSIONING__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_MAP_PRICE_STRIKE_THROUGH__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_WINTER_TREADWELL__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_LUG_NUT_ADD_EDIT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_DISABLE_UNUSED_ROUTES__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_SAME_DAY_VOID__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_TERMINAL_CUSTOMER_PRINTOUT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_ORDER_HISTORY_RESTRICT_REFERENCE_ACTIONS__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@import '../../../../../shared/scss/dt-base';

.container {
  max-width: 200px;
  padding: 15px;
  padding-bottom: 0;
  display: flex;
  align-items: center;
  position: relative;
  @include media($min-sm) {
    padding: 10px;
  }
}

.full-name {
  margin-left: 7px;
  margin-right: 7px;
  max-width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 500;
  font-size: rem(16);
  color: $dt-black;

  @include media($min-sm) {
    font-size: rem(13);
    color: $dark-gray;
  }
}

.launch-sns-container {
  padding: 15px;
  padding-top: 0;
  padding-left: 36px;
  display: block;

  @include media($min-sm) {
    display: none;
  }
}

.organization {
  :global .organization__button {
    padding-bottom: 0;

    @include media($min-sm) {
      padding-bottom: 15px;
    }
  }
}

.account-id {
  font-size: rem(14);
  color: #545459;
  padding-left: 36px;
}
