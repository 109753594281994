@mixin __FF_ORDER_VERSIONING__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_MAP_PRICE_STRIKE_THROUGH__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_WINTER_TREADWELL__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_LUG_NUT_ADD_EDIT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_DISABLE_UNUSED_ROUTES__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_SAME_DAY_VOID__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_TERMINAL_CUSTOMER_PRINTOUT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_ORDER_HISTORY_RESTRICT_REFERENCE_ACTIONS__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@import '../../../shared/scss/dt-base';

.heading {
  margin: 0 0 22px;
  font-size: rem(18);
  font-weight: 500;
  color: $white;

  @include media($min-sm) {
    font-size: rem(26);
  }
}

.subheading {
  display: block;
  margin-top: 7px;
  font-size: rem(14);
  font-weight: 600;
  line-height: 1;

  @include media($min-sm) {
    font-size: 1rem;
  }
}
